import { gql } from '@apollo/client';

// Note: recordCount cannot be accurate for this query, so preference only showing a page count
export const NOISE_EVENTS_HISTORY = gql(`
  query NoiseEventsHistory($filter: FilteredClassifiedNoiseEventsFilters!, $paging: PagingInput!) {
    facility {
      noiseEvents {
        filteredDetailedNoiseEvents(filter: $filter, paging: $paging) {
          previous
          next
          recordCount
          results {
            classifications {
              classificationId
            }
            comments
            deviceId
            id
            isRelevant
            laeq
            lafMax
            sel
            locationId
            utcEndTime
            utcStartTime
            utcUpdatedTime
            windspeed
          }
        }
      }
    }
  }
`);
