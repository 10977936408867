import { Icon } from 'app/components/icon';
import { useAppSelector } from 'app/redux/store';
import { BeamerIconWrapper, NewPostCount } from 'app/views/components/beamer/BeamerIcon.styles';
import { ComponentProps, CSSProperties, useMemo } from 'react';

export const BeamerIcon = (props: ComponentProps<typeof Icon.Updates>) => {
  const { newPostCount } = useAppSelector(state => state.ui);
  const style = useNewPostCountStyle();
  return (
    <BeamerIconWrapper>
      <Icon.Updates {...props} />
      {newPostCount > 0 && (
        <NewPostCount style={style} data-testid="new-post-count">
          {newPostCount}
        </NewPostCount>
      )}
    </BeamerIconWrapper>
  );
};

function useNewPostCountStyle(): CSSProperties {
  const { newPostCount } = useAppSelector(state => state.ui);
  const digits = newPostCount.toString().length;
  const size = `calc(${digits * 1.1}ch + 1ch)`;
  return useMemo(
    () => ({
      width: size,
      height: size,
      lineHeight: size,
      transform: `translate(50%, -50%)`,
    }),
    [size]
  );
}
